import React, { FormEvent } from 'react';
import {
  Alert,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../form/fields';
import AuthLayout from '../auth-layout';
import { FormContainer } from '../../form';
import { registerPreCheckSchema } from '../../../../services/auth-helpers';
import { removeWhitespace } from '../../../../utils/converters';
import DateField from '../../form/fields/date-field';
import FormErrorHTML from '../../form/form-error-html';

interface PreCheckProps {
  loading?: boolean;
  error?: boolean | null;
  onPreCheck: (policyRef: string, dateOfBirth: Date) => void;
}

function RegisterPreCheck({
  onPreCheck,
  loading = false,
  error = null,
}: PreCheckProps) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(registerPreCheckSchema),
    mode: 'onChange',
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { policyRef, dateOfBirth } = getValues();
    if (onPreCheck) {
      onPreCheck(removeWhitespace(policyRef), dateOfBirth);
    }
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" style={{ marginBottom: '1rem' }}>{t('components.auth.register.preCheck.title')}</Typography>
          <Typography gutterBottom sx={{ mb: 3 }}>{t('components.auth.register.preCheck.details')}</Typography>
          <Stack>
            <TextField
              name="policyRef"
              type="text"
              sublabel={t('components.auth.register.preCheck.policyRef')}
              placeholder="xxx xxx xxx"
              transform={removeWhitespace}
            />
            <DateField
              name="dateOfBirth"
              sublabel={t('components.auth.register.preCheck.dateOfBirth')}
              disableFuture
              FormControlProps={{ sx: { '.MuiInputBase-root': { width: 1 } } }}
              SublabelProps={{ sx: { fontWeight: 'bold' } }}
            />
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <FormErrorHTML htmlError={t('components.auth.register.preCheck.errorMessage')} />
              </Alert>
            )}
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValid}
              sx={{
                mt: 2, ml: 'auto', minWidth: 150, maxWidth: '100%',
              }}
            >
              {t('common.next')}
            </LoadingButton>
          </Stack>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default RegisterPreCheck;
