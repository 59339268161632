import React from 'react';
import {
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { CheckCircleOutline } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../auth-layout';

interface PreCheckProps {
  onLoginRedirect: () => void;
}

function RegisterSuccess({ onLoginRedirect }: PreCheckProps) {
  const { t } = useTranslation();

  return (
    <AuthLayout>

      <Stack sx={{
        minHeight: 350,
        justifyContent: 'center',
        alignItems: 'center',
        gap: 2,
      }}
      >
        <CheckCircleOutline color="success" sx={{ fontSize: '3em' }} />
        <Typography variant="h2">{t('components.auth.register.successfulSignup.title')}</Typography>

        <Button
          color="primary"
          variant="contained"
          onClick={onLoginRedirect}
          sx={{ px: 4, mt: 2 }}
        >
          {t('components.auth.register.successfulSignup.loginRedirect')}
        </Button>
      </Stack>
    </AuthLayout>
  );
}

export default RegisterSuccess;
