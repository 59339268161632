import React, { useEffect, useMemo, useState } from 'react';
import * as Auth from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { LinearProgress } from '@mui/material';
import '@aws-amplify/ui-react/styles.css';
import { Outlet } from 'react-router-dom';
import LoginView from '../../routes/login';

const AUTH_STATUSES = {
  LOADING: 'loading',
  AUTHENTICATED: 'authenticated',
  NOT_AUTHENTICATED: 'not_authenticated',
};
const useAuthStatus = () => {
  const [loading, setLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const status = useMemo(() => {
    if (loading) {
      return AUTH_STATUSES.LOADING;
    }

    return isAuthenticated ? AUTH_STATUSES.AUTHENTICATED : AUTH_STATUSES.NOT_AUTHENTICATED;
  }, [loading, isAuthenticated]);

  const loadSession = async () => {
    try {
      await Auth.getCurrentUser();
      setIsAuthenticated(true);
    } catch {
      setIsAuthenticated(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadSession();
  }, []);

  useEffect(() => {
    const unsubscribe = Hub.listen('auth', (data) => {
      const { event } = data.payload;

      if (event === 'signedIn') {
        setIsAuthenticated(true);
      } else if (['signedOut', 'tokenRefresh_failure'].includes(event)) {
        setIsAuthenticated(false);
      }
    });

    return unsubscribe;
  }, []);

  return status;
};

export function AppAuth() {
  const status = useAuthStatus();

  if (status !== AUTH_STATUSES.AUTHENTICATED && status !== AUTH_STATUSES.LOADING) {
    return <LoginView />;
  }

  if (status === AUTH_STATUSES.AUTHENTICATED) {
    return <Outlet />;
  }

  return <LinearProgress />;
}

export default AppAuth;
