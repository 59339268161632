import React, { FormEvent } from 'react';
import {
  Alert,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../form/fields';
import AuthLayout from '../auth-layout';
import { FormContainer } from '../../form';
import { passwordResetSchema } from '../../../../services/auth-helpers';
import GoBack from '../../go-back';

interface PasswordResetProps {
  loading?: boolean;
  error?: string | null;
  success?: boolean | null;
  onPasswordReset: (email: string) => void;
  onCancel: () => void;
}

function PasswordReset({
  onPasswordReset,
  onCancel,
  loading = false,
  error = null,
  success = null,
}: PasswordResetProps) {
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(passwordResetSchema),
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email } = getValues();
    onPasswordReset(email);
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <GoBack onGoBack={onCancel} label={t('components.auth.passwordReset.back')} />
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.passwordReset.title')}</Typography>
          <Stack>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            {success === true ? (
              <>
                <Typography variant="h5">{t('components.auth.passwordReset.confirmationTitle')}</Typography>
                <Typography variant="body2">{t('components.auth.passwordReset.confirmationMessage')}</Typography>
              </>
            ) : (
              <>
                <TextField
                  name="email"
                  type="email"
                  sublabel={t('components.auth.passwordReset.email')}
                />
                <Stack alignItems="flex-end">
                  <LoadingButton
                    color="primary"
                    variant="contained"
                    type="submit"
                    loading={loading}
                    disabled={!isValid}
                    sx={{
                      mt: 2, ml: 'auto', minWidth: 150, maxWidth: '100%',
                    }}
                  >
                    {t('common.next')}
                  </LoadingButton>
                </Stack>
              </>
            )}
          </Stack>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default PasswordReset;
