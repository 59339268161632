import React from 'react';
import { TypographyProps, styled, Typography } from '@mui/material';

interface FormHelperHTMLProps extends TypographyProps {
  htmlError: string;
}

const StyledFormErrorHTML = styled(Typography)(({ theme }) => ({
  '&&': {
    ...theme.typography.body2,
  },
  whiteSpace: 'pre-wrap',
  flexDirection: 'column',
  a: {
    ...theme.typography.h5,
    textDecoration: 'underline',
    color: 'inherit',
  },
}));

function FormErrorHTML(props: FormHelperHTMLProps) {
  const { htmlError, ...rest } = props;
  return (<StyledFormErrorHTML {...rest} dangerouslySetInnerHTML={{ __html: htmlError }} />);
}

export default FormErrorHTML;
