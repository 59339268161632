import React from 'react';
import { ArrowBack } from '@mui/icons-material';
import { Button } from '@mui/material';

function GoBack({ onGoBack, label = '' }: { onGoBack: () => void, label: string }) {
  return (
    <Button
      startIcon={<ArrowBack />}
      disableRipple
      variant="text"
      color="secondary"
      size="small"
      onClick={onGoBack}
      sx={{
        backgroundColor: 'transparent !important',
        padding: 0,
        fontSize: '1rem !important',
        fontFamily: 'FsMeLight',
        mb: 3,
      }}
      aria-label="back button"
    >
      {label}
    </Button>
  );
}

export default GoBack;
