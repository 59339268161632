import * as yup from 'yup';

export const registerPreCheckSchema = yup.object({
  policyRef: yup.string().required('Policy number is required'),
  dateOfBirth: yup.date().required('Date of birth is required'),
});

export const passwordRequirements = {
  minChar: 10,
  lowercase: /[a-z]/,
  uppercase: /[A-Z]/,
  number: /\d/,
  symbol: /[\W_]/,
};

export const passwordRequirementIds = {
  minChar: 'minChar',
  lowercase: 'lowercase',
  uppercase: 'uppercase',
  number: 'number',
  symbol: 'symbol',
  match: 'match',
};

export const memberPortalSetPasswordSchema = yup.object({
  password: yup.string()
    .trim()
    .required('Password is required')
    .min(passwordRequirements.minChar)
    .matches(passwordRequirements.lowercase, 'Password must contain at least one lowercase letter')
    .matches(passwordRequirements.uppercase, 'Password must contain at least one uppercase letter')
    .matches(passwordRequirements.number, 'Password must contain at least one number')
    .matches(passwordRequirements.symbol, 'Password must contain at least one symbol'),
  confirmPassword: yup.string().required().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const loginSchema = yup.object({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required'),
});

export const passwordResetSchema = yup.object({
  email: yup.string().email().required('Email is required'),
});

export const changePasswordSchema = yup.object({
  verficationCode: yup.string().required('Verification code is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const forgotPasswordSchema = yup.object({
  email: yup.string().required('Email is required'),
  verficationCode: yup.string().required('Verification code is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const newPasswordSchema = yup.object({
  phoneNumber: yup.string().required('Mobile number is required'),
  password: yup.string().required('Password is required').min(10, 'Password must have at least 10 characters'),
  confirmPassword: yup.string().oneOf([yup.ref('password')], 'Your passwords must match'),
});

export const verificationCodeSchema = yup.object({
  verficationCode: yup.string().required('Verification code is required'),
  rememberMe: yup.boolean(),
});

export default {};
