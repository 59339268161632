export const cognito = {
  cognitoUserPoolId: 'eu-west-1_9wmJ4g6CO',
  cognitoIdentityPoolId: 'eu-west-1:a6f91429-9a47-4095-94e7-807f717fedcd',
  cognitoAppClientId: 'k872o1h30cnbvjc13uq1sf8pi',
  cognitoUserPoolDomain: 'theexeter-member-dev.auth.eu-west-1.amazoncognito.com',
  cognitoRegion: 'eu-west-1',
};

export const googleAnalyticsTag = 'G-PD0M2WX1H9';
export const closedClaimsDisplayDate = '2024-12-01T00:00:00Z';

export const featureFlags = {
  staticClaimGuide: false,
};

export default {};
