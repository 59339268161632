import React, { useState } from 'react';
import * as Auth from 'aws-amplify/auth';
import { toast } from 'react-toastify';
import PasswordReset from '../components/auth/views/password-reset';
import SetPassword from '../components/auth/views/set-password';
import VerificationCode from '../components/auth/views/verification-code';

const VIEWS = {
  FORGOTTEN_PASSWORD: 'forgottenPassword',
  RESET_PASSWORD: 'resetPassword',
  RESET_CODE: 'resetCode',
};

const defaultErrors = {
  forgottenPassword: '',
  resetPassword: '',
  resetCode: '',
};

interface ForgottenViewProps {
  onCancel: () => void;
  onSuccess: () => void
}

function ForgottenView({ onCancel, onSuccess }: ForgottenViewProps) {
  const [errors, setErrors] = useState(defaultErrors);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState(VIEWS.FORGOTTEN_PASSWORD);

  const [userData, setUserData] = useState({ email: '', phone: '', password: '' });

  const onBeforeSubmit = () => {
    setLoading(true);
    setErrors(defaultErrors);
  };

  const onPasswordReset = async (email: string) => {
    setErrors(defaultErrors);
    setUserData({ email, phone: '', password: '' });
    setCurrentView(VIEWS.RESET_PASSWORD);
  };

  const onPasswordResetSubmit = async (password: string) => {
    onBeforeSubmit();

    try {
      const data = await Auth.resetPassword({ username: userData.email });
      setUserData({ ...userData, phone: data.nextStep.codeDeliveryDetails.destination || '', password });
      setCurrentView(VIEWS.RESET_CODE);
    } catch (err) {
      setErrors({ ...errors, resetPassword: (err as Error).message });
    }

    setLoading(false);
  };

  const onResetPasswordCodeSubmit = async (confirmationCode: string) => {
    onBeforeSubmit();
    try {
      const { email, password: newPassword } = userData;
      await Auth.confirmResetPassword({ username: email, confirmationCode, newPassword });
      onSuccess();
      toast('Password updated', {
        position: 'bottom-left',
        type: 'success',
      });
    } catch (err) {
      setErrors({ ...errors, resetCode: (err as Error).message });
    }

    setLoading(false);
  };

  const onResendResetCode = async () => {
    onBeforeSubmit();

    try {
      await Auth.resetPassword({ username: userData.email });
      toast('Code sent successfully', {
        position: 'bottom-left',
        type: 'success',
      });
    } catch (err) {
      setErrors({ ...errors, resetCode: (err as Error).message });
    }

    setLoading(false);
  };

  return (
    <>
      {currentView === VIEWS.FORGOTTEN_PASSWORD && (
        <PasswordReset
          onPasswordReset={onPasswordReset}
          onCancel={onCancel}
          loading={loading}
          error={errors.forgottenPassword}
        />
      )}
      {currentView === VIEWS.RESET_PASSWORD && (
        <SetPassword
          onSetPassword={onPasswordResetSubmit}
          onCancel={() => setCurrentView(VIEWS.FORGOTTEN_PASSWORD)}
          email={userData.email}
          loading={loading}
          error={errors.resetPassword}
        />
      )}
      {currentView === VIEWS.RESET_CODE && (
        <VerificationCode
          phoneNumber={userData.phone}
          email={userData.email}
          onVerificationCode={onResetPasswordCodeSubmit}
          onResendCode={onResendResetCode}
          onCancel={() => setCurrentView(VIEWS.RESET_PASSWORD)}
          loading={loading}
          error={errors.resetCode}
        />
      )}
    </>
  );
}

export default ForgottenView;
