import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

export const WelcomeContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.backgroundGreyDark,
  color: theme.palette.common.white,
  display: 'flex',
  height: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  minHeight: 215,
  padding: 0,
  paddingLeft: theme.spacing(2),
  paddingRight: theme.spacing(2),
  '& > div': {
    flexGrow: 1,
  },
  '& h3': {
    fontFamily: theme.typography.h6.fontFamily,
  },
  '& a': {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
}));

export default WelcomeContainer;
