import moment, { Moment } from 'moment';

export const clientDateFormat = 'DD/MM/YYYY';
export const serverDateFormat = 'YYYY-MM-DD';
export const clientDateTimeFormat = 'DD/MM/YYYY [at] HH:mm';
export const serverDateTimeFormat = 'YYYY-MM-DD HH:mm:ss';

export function parseServerDate(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, serverDateFormat);
  return date.isValid() ? date : null;
}

export function parseServerDateTime(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, serverDateTimeFormat);
  return date.isValid() ? date : null;
}

export function formatServerDate(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(serverDateFormat);
}

export function parseClientDate(dateStr: string | null | undefined): Moment | null {
  if (!dateStr) {
    return null;
  }
  const date = moment(dateStr, clientDateFormat);
  return date.isValid() ? date : null;
}

export function formatClientDate(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(clientDateFormat);
}

export function formatClientDateTime(date: Moment | null | undefined): string | null {
  if (!date || !date.isValid() || date.year().toString().length !== 4) {
    return null;
  }
  return date.format(clientDateTimeFormat);
}

export function asClientDate(serverDate: string | null | undefined): string | null {
  return formatClientDate(parseServerDate(serverDate));
}

export function asClientDateTime(serverDate: string | null | undefined): string | null {
  return formatClientDateTime(parseServerDateTime(serverDate));
}

export function asAge(dateOfBirthServerDate: string | null | undefined): number | null {
  return dateOfBirthServerDate ? moment().diff(dateOfBirthServerDate, 'years') : null;
}

export function asStringOrNull(value: unknown) {
  if (value === null || value === undefined || value === '') {
    return null;
  }
  return `${value}`;
}

export function removeWhitespace(value: string): string {
  return value.replace(/ /g, '');
}

export const currencyFormatter = new Intl.NumberFormat('en-UK', {
  style: 'currency',
  currency: 'GBP',
  minimumFractionDigits: 0,
});

export function numberToMoney(num: number | null | undefined): string | null {
  if (!num) {
    return null;
  }
  return currencyFormatter.format(num);
}

export function stringToMoney(str: string | null | undefined): string | null {
  if (!str) {
    return null;
  }
  return currencyFormatter.format(parseFloat(str));
}

export function titleCase(str: string | null | undefined): string | null {
  if (!str) {
    return null;
  }
  return str.charAt(0).toUpperCase() + str.substring(1).toLowerCase();
}

export function formatWaitingPeriod(str: string | null | undefined): string | null {
  if (!str) {
    return null;
  }
  return str.split('_').at(-1) ?? null;
}

export function byteToMB(value: number): number {
  return value / (1024 ** 2);
}

export function mbToByte(value: number): number {
  return value * (1024 ** 2);
}
