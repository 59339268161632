import http from './http';

export interface ValidationData {
  token: string;
  emailAddress: string;
  policyReference: string;
  dateOfBirth: Date;
}

async function validateRegistrationPreCheck(validationData: ValidationData): Promise<void> {
  return http.post('/api/v1/bff/tokenised-links/member/validation', validationData);
}

async function postMemberRegistration(validationData: ValidationData, password: string): Promise<void> {
  return http.post('/api/v1/bff/tokenised-links/member/registration', { validationData, password });
}

const authApi = {
  validateRegistrationPreCheck,
  postMemberRegistration,
};

export default authApi;
