import React, { FormEvent, useState } from 'react';
import {
  Alert,
  Box,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  Circle, Done, Visibility, VisibilityOff,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../form/fields';
import AuthLayout from '../auth-layout';
import { FormContainer } from '../../form';
import { memberPortalSetPasswordSchema, passwordRequirementIds, passwordRequirements } from '../../../../services/auth-helpers';

import GoBack from '../../go-back';

enum Variants {
  Reset = 'reset',
  Signup = 'signup',
}

interface SetPasswordProps {
  loading?: boolean;
  error?: string | null;
  onSetPassword: (password: string) => void;
  email: string;
  onCancel: () => void;
  variant?: 'reset' | 'signup'
}

function SetPassword({
  onSetPassword,
  loading = false,
  error = null,
  email,
  onCancel,
  variant = 'reset',
}: SetPasswordProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState<boolean>(false);

  const { t } = useTranslation();
  const translateTarget = variant === Variants.Reset ? 'components.auth.resetPassword' : 'components.auth.register.setupPassword';

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(memberPortalSetPasswordSchema),
    mode: 'onChange',
  });

  const { formState: { isValid }, watch } = formMethods;
  const password = watch('password') || '';
  const confirmPassword = watch('confirmPassword') || '';

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (onSetPassword) {
      onSetPassword(password);
    }
  };

  const getRequirementlabelObject = (id: string) => ({
    id,
    label: t(`components.auth.resetPassword.passwordRequirements.${id}`),
  });

  const requirements = [
    {
      ...getRequirementlabelObject(passwordRequirementIds.minChar),
      test: (field: string) => field.trim().length >= passwordRequirements.minChar,
    },
    {
      ...getRequirementlabelObject(passwordRequirementIds.lowercase),
      test: (field: string) => passwordRequirements.lowercase.test(field.trim()),
    },
    {
      ...getRequirementlabelObject(passwordRequirementIds.uppercase),
      test: (field: string) => passwordRequirements.uppercase.test(field.trim()),
    },
    {
      ...getRequirementlabelObject(passwordRequirementIds.number),
      test: (field: string) => passwordRequirements.number.test(field.trim()),
    },
    {
      ...getRequirementlabelObject(passwordRequirementIds.symbol),
      test: (field: string) => passwordRequirements.symbol.test(field.trim()),
    },
    {
      ...getRequirementlabelObject(passwordRequirementIds.match),
      test: (field: string) => confirmPassword.trim() && field === confirmPassword,
    },
  ];

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <GoBack onGoBack={onCancel} label={email} />
          <Typography variant="h3">{t(`${translateTarget}.title`)}</Typography>
          <Stack>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}
            <TextField
              name="password"
              hideError
              type={showPassword ? 'text' : 'password'}
              sublabel={t(`${translateTarget}.password`)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ mr: 0 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              name="confirmPassword"
              hideError
              type={showConfirmPassword ? 'text' : 'password'}
              sublabel={t(`${translateTarget}.confirmPassword`)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      edge="end"
                      sx={{ mr: 0 }}
                    >
                      {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <Box>
              <Typography variant="h4" mt={1}>{t('components.auth.resetPassword.passwordRequirements.title')}</Typography>
              <List data-testid="requirement-items">
                {requirements.map((item) => (
                  <ListItem
                    key={item.id}
                    id={item.id}
                    data-testid="requirement-item"
                    disablePadding
                    sx={{ gap: 1 }}
                  >
                    <Stack width="1em" sx={{ textAlign: 'left' }}>
                      {item.test(password)
                        ? <Done sx={{ fontSize: '1em' }} color="success" />
                        : <Circle sx={{ fontSize: '0.6em' }} color="disabled" />}
                    </Stack>
                    <ListItemText
                      primary={item.label}
                      sx={{
                        color: (theme) => (item.test(password)
                          ? theme.palette.success.main
                          : theme.palette.text.primary),
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </Box>
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValid}
              sx={{
                mt: 2, ml: 'auto', minWidth: 150, maxWidth: '100%',
              }}
            >
              {t(`${translateTarget}.submitBtnLabel`)}
            </LoadingButton>
          </Stack>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default SetPassword;
