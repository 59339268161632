import React, { FormEvent, useState } from 'react';
import {
  Alert,
  IconButton,
  InputAdornment,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { useTranslation } from 'react-i18next';
import { TextField } from '../../form/fields';
import AuthLayout from '../auth-layout';
import { FormContainer } from '../../form';
import { loginSchema } from '../../../../services/auth-helpers';
import { removeWhitespace } from '../../../../utils/converters';
import FormErrorHTML from '../../form/form-error-html';

interface LoginProps {
  loading?: boolean;
  error?: boolean | null;
  onLogin: (email: string, password: string) => void;
  onForgotPassword: () => void;
}

function Login({
  onLogin,
  onForgotPassword,
  loading = false,
  error = null,
}: LoginProps) {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { t } = useTranslation();

  const formMethods = useForm({
    defaultValues: {},
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });

  const { formState: { isValid }, getValues } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { email, password } = getValues();
    if (onLogin) {
      onLogin(removeWhitespace(email), password);
    }
  };

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom>{t('components.auth.login.title')}</Typography>
          <Stack>
            <TextField
              name="email"
              type="text"
              sublabel={t('components.auth.login.email')}
              placeholder={t('components.auth.login.email')!}
              transform={removeWhitespace}
            />
            <TextField
              name="password"
              type={showPassword ? 'text' : 'password'}
              sublabel={t('components.auth.login.password')}
              placeholder={t('components.auth.login.password')!}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                      sx={{ mr: 0 }}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>
          <Stack alignItems="flex-start">
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <Link
              component="button"
              type="button"
              color="common.blue"
              sx={{ mb: 2 }}
              onClick={onForgotPassword}
            >
              {t('components.auth.login.forgotPassword')}
            </Link>
            {error && (
              <Alert severity="error" sx={{ mb: 2 }}>
                <FormErrorHTML htmlError={t('components.auth.login.errorMessage')} />
              </Alert>
            )}
            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValid}
              sx={{
                mt: 2, ml: 'auto', minWidth: 150, maxWidth: '100%',
              }}
            >
              {t('components.auth.login.signIn')}
            </LoadingButton>
          </Stack>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default Login;
