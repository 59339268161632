import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import RegisterPreCheck from '../components/auth/views/register-pre-check';
import SetPassword from '../components/auth/views/set-password';
import RegisterSuccess from '../components/auth/views/register-success';

import authApi, { ValidationData } from '../../services/auth-api';

export const getUrlParams = () => {
  const params = new URLSearchParams(window.location.search);
  return {
    signature: params.get('signature') || '',
    email: atob(params.get('id') || '') || '',
  };
};

const VIEWS = {
  PRE_CHECK: 'preCheck',
  SIGNUP: 'signup',
  SUCCESS: 'success',
};

const defaultErrors = {
  preCheck: '',
  signup: '',
};

function RegisterView() {
  const [errors, setErrors] = useState(defaultErrors);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState(VIEWS.PRE_CHECK);
  const [validationData, setValidationData] = useState<ValidationData>({
    token: '',
    emailAddress: '',
    policyReference: '',
    dateOfBirth: new Date(),
  });

  const navigate = useNavigate();

  const onBeforeSubmit = () => {
    setLoading(true);
    setErrors(defaultErrors);
  };

  const onPreCheckSubmit = async (policyRef: string, dateOfBirth: Date) => {
    onBeforeSubmit();
    const { signature, email } = getUrlParams();
    const submitData = {
      token: signature,
      emailAddress: email,
      policyReference: policyRef,
      dateOfBirth,
    };
    setValidationData(submitData);

    try {
      await authApi.validateRegistrationPreCheck(submitData);
      setCurrentView(VIEWS.SIGNUP);
    } catch (error) {
      setErrors({ ...errors, preCheck: (error as Error).message });
    }

    setLoading(false);
  };

  const onSetPasswordSubmit = async (password: string) => {
    onBeforeSubmit();
    try {
      await authApi.postMemberRegistration(validationData, password);
      setCurrentView(VIEWS.SUCCESS);
    } catch (error) {
      setErrors({ ...errors, preCheck: (error as Error).message });
    }

    setLoading(false);
  };

  return (
    <>
      {currentView === VIEWS.PRE_CHECK && <RegisterPreCheck onPreCheck={onPreCheckSubmit} loading={loading} error={!!errors.preCheck} />}
      {currentView === VIEWS.SIGNUP && (
        <SetPassword
          onSetPassword={onSetPasswordSubmit}
          onCancel={() => setCurrentView(VIEWS.PRE_CHECK)}
          variant="signup"
          loading={loading}
          error={errors.signup}
          email={validationData.emailAddress}
        />
      )}
      {currentView === VIEWS.SUCCESS && <RegisterSuccess onLoginRedirect={() => navigate('/')} />}
    </>
  );
}

export default RegisterView;
