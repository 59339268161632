import React, { useCallback, useEffect, useRef } from 'react';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useParams,
} from 'react-router-dom';
import { LinearProgress } from '@mui/material';
import { Error404, ErrorCatch } from './error';
import {
  DashboardPage,
  DocumentsPage,
  DocumentPage,
  ClaimDetailsPage,
  ClaimEvidencePage,
  ClaimDocumentPage,
} from './pages';
import { Layout } from '../components/layout';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { loadMember, selectMemberState } from '../features/MemberSlice';
import Claims from './claims';
import Documents from './documents';
import Document from './document';
import ClaimDetails from './claim-details';
import RegisterView from './register';
import { loadClaimDetail } from '../features/ClaimDetailSlice';
import ClaimEvidence from './claim-evidence';
import { DocumentType } from './document/document';
import { AppAuth } from '../components/app-auth';

function InitMember() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(loadMember());
  }, [dispatch]);
  return <Outlet />;
}

function InitClaimDetail() {
  const dispatch = useAppDispatch();
  const { claimId } = useParams();
  useEffect(() => {
    if (claimId) {
      dispatch(loadClaimDetail(claimId));
    }
  }, [dispatch]);
  return <Outlet />;
}

function AuthenticatedRoutes() {
  const dispatch = useAppDispatch();
  const state = useAppSelector(selectMemberState);
  const initialised = useRef<boolean>(false);

  const init = useCallback(async () => {
    if (!initialised.current && state.status === 'none') {
      initialised.current = true;
      await dispatch(loadMember());
    }
  }, [dispatch, state]);

  useEffect(() => {
    init();
  }, []);

  if (state.status !== 'available') return <LinearProgress />;

  return <Outlet />;
}

function AppRoutes() {
  const routes = createBrowserRouter(
    createRoutesFromElements(
      <Route path={DashboardPage.path}>
        <Route element={<AppAuth />}>
          <Route element={<AuthenticatedRoutes />}>
            <Route element={<InitMember />}>
              <Route
                index
                element={(
                  <Layout>
                    <Claims />
                  </Layout>
                )}
                errorElement={<ErrorCatch />}
              />
              <Route path={ClaimDetailsPage.path}>
                <Route element={<InitClaimDetail />}>
                  <Route
                    index
                    element={(
                      <Layout>
                        <ClaimDetails />
                      </Layout>
                    )}
                  />
                  <Route
                    path={ClaimEvidencePage.path}
                    element={(
                      <Layout>
                        <ClaimEvidence />
                      </Layout>
                    )}
                  />
                </Route>
              </Route>
              <Route
                index
                path={DocumentsPage.path}
                element={(
                  <Layout>
                    <Documents />
                  </Layout>
                )}
                errorElement={<ErrorCatch />}
              />
              <Route
                index
                path={DocumentPage.path}
                element={(
                  <Document />
                )}
                errorElement={<ErrorCatch />}
              />
              <Route
                index
                path={ClaimDocumentPage.path}
                element={(
                  <Document type={DocumentType.CLAIM} />
                )}
                errorElement={<ErrorCatch />}
              />
            </Route>
            <Route
              path="*"
              element={(
                <Error404 />
              )}
            />
          </Route>
        </Route>
        <Route path="/register" element={<RegisterView />} />
      </Route>,
    ),
  );

  return <RouterProvider router={routes} />;
}

export default AppRoutes;
