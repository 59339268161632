import React, {
  ClipboardEvent,
  FocusEvent,
  FormEvent,
  KeyboardEvent,
  MouseEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  Checkbox,
  FormControlLabel,
  FormLabel,
  Link,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../auth-layout';
import { FormContainer } from '../../form';
import { verificationCodeSchema } from '../../../../services/auth-helpers';
import { VerficationCodeInputContainer } from '../auth.styles';
import GoBack from '../../go-back';

const CODE_LENGTH = 6;

function buildNumberRegex(matchLength = 1) {
  const numberRegex = '^[0-9]{n}$';
  const lengthStr = matchLength.toFixed();
  return new RegExp(numberRegex.split('n').join(lengthStr));
}

function isValidValidationCode(code: string[]): boolean {
  return code.join('').length === CODE_LENGTH;
}

interface VerificationCodeProps {
  phoneNumber: string;
  email: string;
  loading?: boolean;
  error?: string | null;
  onVerificationCode: (verificationCode: string, rememberMe: boolean) => void;
  onCancel: () => void;
  onResendCode: () => void;
}

function VerificationCode({
  phoneNumber,
  email,
  onVerificationCode,
  onCancel,
  onResendCode,
  loading = false,
  error = null,
}: VerificationCodeProps) {
  const { t } = useTranslation();
  const [code, setCode] = useState<string[]>(Array.from(Array(CODE_LENGTH).keys()).map(() => ''));
  const ref = useRef<HTMLDivElement>(null);

  const formMethods = useForm({
    defaultValues: {
      rememberMe: false,
    },
    resolver: yupResolver(verificationCodeSchema),
    mode: 'onChange',
  });

  const { getValues, setValue } = formMethods;

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const { verficationCode, rememberMe } = getValues();
    onVerificationCode(verficationCode, rememberMe ?? false);
  };

  const handleFocus = (event: FocusEvent) => {
    const target = event.target as HTMLInputElement;
    if (target) {
      target.select();
    }
  };

  const resendCodeTimerRef = useRef<NodeJS.Timeout | null>(null);
  const [resendCodeTimer, setResendCodeTimer] = useState(0);

  const handleResendCode = (event: MouseEvent) => {
    event.preventDefault();
    if (loading) return;

    let timer = 30;
    setResendCodeTimer(30);
    resendCodeTimerRef.current = setInterval(() => {
      if (timer < 1) {
        return clearInterval(resendCodeTimerRef.current as NodeJS.Timeout);
      }
      timer -= 1;
      return setResendCodeTimer(timer);
    }, 1000);
    onResendCode();
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>, codeIndex: number) => {
    const isValidChar = buildNumberRegex().test(event.key);
    const isBackspace = event.key === 'Backspace';
    const isTab = event.key === 'Tab';

    if (isTab) {
      return;
    }

    if (!isValidChar && !isBackspace) {
      return;
    }

    const newChar = isBackspace ? '' : event.key;
    const newIndex = codeIndex + (isBackspace ? -1 : 1);
    const target = ref.current?.children[newIndex]?.querySelector('input');

    setCode((currentCode) => currentCode.map((item, k) => (codeIndex === k ? newChar : item)));

    if (target) {
      target.focus();
      setTimeout(() => target.select());
    }
  };

  const verifyAndFillCode = (verificationCode: string) => {
    const isValidCode = buildNumberRegex(CODE_LENGTH).test(verificationCode);

    if (isValidCode) {
      setCode((currentCode) => currentCode.map((_, k) => (verificationCode[k])));
      ref.current?.children[CODE_LENGTH - 1]?.querySelector('input')?.focus();
    }
  };

  const handlePaste = (e: ClipboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    // Fallback when handleInput is not fired (e.g. paste on mobile)
    const pasteData = e.clipboardData.getData('text');
    verifyAndFillCode(pasteData);
  };

  const handleInput = (e: Event) => {
    const inputEvent = e as InputEvent;
    const inputData = inputEvent.data || '';
    verifyAndFillCode(inputData);
  };

  useEffect(() => {
    window.addEventListener('input', handleInput);
    return () => window.removeEventListener('input', handleInput);
  }, []);

  useEffect(() => {
    setValue('verficationCode', code.join(''));
  }, [code]);

  return (
    <AuthLayout>
      <FormProvider {...formMethods}>
        <GoBack onGoBack={onCancel} label={email} />
        <FormContainer onSubmit={handleSubmit}>
          <Typography variant="h3" gutterBottom style={{ marginBottom: '1rem' }}>{t('components.auth.verificationCode.title')}</Typography>
          <Typography variant="body2" gutterBottom mb={3}>
            {`${t('components.auth.verificationCode.sentMessage')} ${phoneNumber}`}
          </Typography>
          <Stack sx={{ mb: 2 }}>
            {error && (<Alert severity="error" sx={{ mb: 2 }}>{error}</Alert>)}

            <FormLabel>{t('components.auth.verificationCode.codeLabel')}</FormLabel>
            <VerficationCodeInputContainer direction="row" gap={1} ref={ref}>
              {Array.from(Array(CODE_LENGTH).keys()).map((key) => (
                <TextField
                  key={key}
                  name={`code${key}`}
                  type="tel"
                  autoComplete="off"
                  inputProps={{
                    min: 1,
                    max: 1,
                    pattern: '[0-9]*',
                    onKeyDown: (event) => handleKeyDown(event, key),
                    onPaste: handlePaste,
                  }}
                  onFocus={(event) => handleFocus(event)}
                  value={code[key]}
                />
              ))}
            </VerficationCodeInputContainer>
            <Typography
              variant="body2"
              gutterBottom
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mb: 2,
              }}
            >
              {t('components.auth.verificationCode.noCodeMessage')}
              {resendCodeTimer > 0
                ? ` (Try again in ${resendCodeTimer}s)`
                : <Link href="../" color="common.blue" onClick={handleResendCode}>{t('components.auth.verificationCode.resend')}</Link>}
            </Typography>
            <FormControlLabel
              control={(
                <Checkbox
                  onChange={(e, checked) => setValue('rememberMe', checked)}
                  value={getValues().rememberMe}
                />
              )}
              label={t('components.auth.verificationCode.rememberLabel')}
            />
          </Stack>
          <Stack alignItems="flex-start">

            <LoadingButton
              color="primary"
              variant="contained"
              type="submit"
              loading={loading}
              disabled={!isValidValidationCode(code)}
              sx={{
                mt: 2, ml: 'auto', minWidth: 150, maxWidth: '100%',
              }}
            >
              {t('common.verify')}
            </LoadingButton>
          </Stack>
        </FormContainer>
      </FormProvider>
    </AuthLayout>
  );
}

export default VerificationCode;
