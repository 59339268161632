import React from 'react';
import { Amplify } from 'aws-amplify';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { Authenticator } from '@aws-amplify/ui-react';
import 'react-toastify/dist/ReactToastify.css';
import theme from './theme';
import { store } from './store';
import { cognito } from './exports';
import AppRoutes from './routes/routes';

const awsconfig = {
  Auth: {
    Cognito: {
      userPoolId: cognito.cognitoUserPoolId,
      userPoolClientId: cognito.cognitoAppClientId,
    },
  },
};

Amplify.configure(awsconfig);

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <CssBaseline />
          <Authenticator.Provider>
            <AppRoutes />
          </Authenticator.Provider>
        </LocalizationProvider>
      </Provider>
      <ToastContainer newestOnTop />
    </ThemeProvider>
  );
}

export default App;
