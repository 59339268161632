declare module '@mui/material/styles/createPalette' {
  interface CommonColors {
    black: string
    white: string
    green: string
    yellow: string
    yellow200: string
    red: string
    orange: string
    blue: string
    greyLight: string
    greyDark: string
    primary: string
    primary800: string
    primary600: string
    primary400: string
    primary200: string
    secondary: string
    secondary800: string
    secondary600: string
    secondary400: string
    secondary200: string
    secondary100: string
    surface: string
    surface1: string
    surface2: string
    surface3: string
    surface4: string
    surface5: string
    surface6: string
    surface7: string
    surface8: string
    surface9: string
    surface10: string
    surface11: string
    background: string
    background1: string
    background2: string
    background3: string
    background4: string
    backgroundGreyDark: string,
    textDark: string
    textGrey: string
    textLight: string
    textLink: string
  }
}

const colors = {
  black: '#000000',
  white: '#FFFFFF',
  green: '#18A564',
  yellow: '#FFB800',
  yellow200: '#FBEA98',
  red: '#CA444B',
  orange: '#e98708',
  blue: '#133BC0',
  greyLight: '#8691A2',
  greyDark: '#2F2F2F',
  primary: '#FFED00',
  primary800: '#FFF133',
  primary600: '#FFF466',
  primary400: '#FFF899',
  primary200: '#FFFBCC',
  secondary: '#525251',
  secondary800: '#6C6967',
  secondary600: '#7D7A78',
  secondary400: '#A19E9A',
  secondary200: '#B3AFAB',
  surface: '#212121',
  surface1: '#525251',
  surface2: '#6C6967',
  surface3: '#7D7A78',
  surface4: '#A19E9A',
  surface5: '#B3AFAB',
  surface6: '#D7D2CD',
  surface7: '#E3E0DC',
  surface8: '#ECEAE8',
  surface9: '#F3F1F0',
  surface10: '#F9F8F7',
  surface11: '#FCFCFB',
  surface12: '#FFFFFF',
  background: '#FFFFFF',
  background1: '#F8E4E9',
  background2: '#E1E7EE',
  background3: '#DEEFEA',
  background4: '#F5F5F5',
  backgroundGreyDark: '#4c4c4c',
  textDark: '#1B1A33',
  textGrey: '#8691A2',
  textDarkGrey: '#61728C',
  textLight: '#FFFFFF',
  textLink: '#133BC0',
};

const variables = {
  colors,
};

export default variables;
