import React from 'react';
import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AuthContainer, AuthPanel } from './auth.styles';
import ExeterLogo from '../../../assets/images/global/exeter-logo.svg';

function AuthLayout({ children }: { children: React.ReactNode }) {
  const { t } = useTranslation();

  return (
    <AuthContainer maxWidth={false} disableGutters>
      <Stack direction="column" gap={2} alignItems="flex-start">
        <img src={ExeterLogo} width="100" alt={t('components.appBar.logoAlt')!} />
        <AuthPanel elevation={0}>
          {children}
        </AuthPanel>
      </Stack>
    </AuthContainer>
  );
}

export default AuthLayout;
