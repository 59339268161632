import React, { useState } from 'react';
import * as Auth from 'aws-amplify/auth';
import Login from '../components/auth/views/login';
import VerificationCode from '../components/auth/views/verification-code';
import SetPassword from '../components/auth/views/set-password';
import ForgottenPasswordView from '../forgotten-password';

const VIEWS = {
  SIGNIN: 'signin',
  FORGOTTEN_PASSWORD: 'forgottenPassword',
  CONFIRM_NEW_PASSWORD: 'confirmNewPassword',
  SIGNIN_CODE: 'signinCode',
};

const defaultErrors = {
  signin: '',
  forgottenPassword: '',
  confirmNewPassword: '',
  signinCode: '',
};

function LoginView() {
  const [errors, setErrors] = useState(defaultErrors);
  const [loading, setLoading] = useState(false);
  const [currentView, setCurrentView] = useState(VIEWS.SIGNIN);

  const [userData, setUserData] = useState({ email: '', phone: '', password: '' });
  const [currentEmail, setCurrentEmail] = useState('');

  const onBeforeSubmit = () => {
    setLoading(true);
    setErrors(defaultErrors);
  };

  const onConfirmNewPassword = async (password: string) => {
    try {
      await Auth.confirmSignIn({ challengeResponse: password });
      setCurrentView(VIEWS.SIGNIN_CODE);
    } catch (err) {
      setErrors({ ...errors, confirmNewPassword: (err as Error).message });
    }
    setLoading(false);
  };

  const onLoginSubmit = async (email: string, password: string) => {
    onBeforeSubmit();

    try {
      const { nextStep } = await Auth.signIn({ username: email, password });

      setCurrentEmail(email);
      setUserData({ ...userData, email, password });

      if (
        nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_SMS_CODE'
        || nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_EMAIL_CODE'
      ) {
        setUserData((prevState) => ({ ...prevState, phone: nextStep.codeDeliveryDetails?.destination || '' }));
        setCurrentView(VIEWS.SIGNIN_CODE);
      } else if (nextStep.signInStep === 'CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED') {
        setCurrentView(VIEWS.CONFIRM_NEW_PASSWORD);
      }
    } catch (err) {
      setErrors({ ...errors, signin: (err as Error).message });
    }

    setLoading(false);
  };

  const onSigninCodeSubmit = async (code: string, rememberMe: boolean) => {
    onBeforeSubmit();
    try {
      await Auth.confirmSignIn({ challengeResponse: code });

      if (rememberMe) {
        await Auth.rememberDevice();
      } else {
        await Auth.forgetDevice();
      }
      setCurrentView(VIEWS.SIGNIN);
    } catch (err) {
      setErrors({ ...errors, signinCode: (err as Error).message });
    }

    setLoading(false);
  };

  const onResendLoginCode = async () => {
    const { email, password } = userData;

    try {
      await onLoginSubmit(email, password);
    } catch (err) {
      setErrors({ ...errors, signinCode: (err as Error).message });
    }
  };

  return (
    <>
      {currentView === VIEWS.SIGNIN && (
        <Login
          onLogin={onLoginSubmit}
          onForgotPassword={() => setCurrentView(VIEWS.FORGOTTEN_PASSWORD)}
          loading={loading}
          error={!!errors.signin}
        />
      )}
      {currentView === VIEWS.FORGOTTEN_PASSWORD && (
        <ForgottenPasswordView onCancel={() => setCurrentView(VIEWS.SIGNIN)} onSuccess={() => setCurrentView(VIEWS.SIGNIN)} />
      )}
      {currentView === VIEWS.CONFIRM_NEW_PASSWORD && (
        <SetPassword
          onSetPassword={onConfirmNewPassword}
          onCancel={() => setCurrentView(VIEWS.SIGNIN)}
          email={currentEmail}
          loading={loading}
          error={errors.confirmNewPassword}
        />
      )}
      {currentView === VIEWS.SIGNIN_CODE && (
        <VerificationCode
          phoneNumber={userData.phone}
          email={currentEmail}
          onVerificationCode={onSigninCodeSubmit}
          onResendCode={onResendLoginCode}
          onCancel={() => setCurrentView(VIEWS.SIGNIN)}
          loading={loading}
          error={errors.signinCode}
        />
      )}
    </>
  );
}

export default LoginView;
